<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card class="form-dialog form-dialog--new-user">
      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>

      <h1 class="form-dialog__title">{{ $t('add_a_new_user') }}</h1>

      <v-form class="mt-8">
        <v-row dense>
          <v-col cols="12" style="height: 64px">
            <div class="text-input-label">{{ $t('g_name') }}</div>
            <v-text-field
              solo
              flat
              dense
              validate-on-blur
              background-color="#F6F6F6"
              v-model="formData.first_name"
              class="pkmn-input"
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" style="height: 64px">
            <div class="text-input-label">{{ $t('g_surname') }}</div>
            <v-text-field
              solo
              flat
              dense
              validate-on-blur
              background-color="#F6F6F6"
              v-model="formData.last_name"
              class="pkmn-input"
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" style="height: 64px">
            <div class="text-input-label">{{ $t('g_email') }}</div>
            <v-text-field
              solo
              flat
              dense
              validate-on-blur
              background-color="#F6F6F6"
              v-model="formData.email"
              class="pkmn-input"
              :rules="[rules.required, rules.email]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" style="height: 60px">
            <div class="text-input-label">{{ $t('g_role') }}</div>
            <v-select
              v-model="formData.role"
              :items="roleForSuperAdminOptions"
              item-value="value"
              item-text="text"
              solo
              flat
              validate-on-blur
              background-color="#F6F6F6"
              class="pkmn-input"
              :rules="[rules.required]"
              dense
            ></v-select>
          </v-col>
        </v-row>

        <v-row dense v-if="errorMessage">
          <v-col cols="12" class="text-right">
            <p class="pkmn-form__error" v-html="errorMessage"></p>
          </v-col>
        </v-row>

        <v-row class="form-dialog__button-wrap">
          <v-col cols="12">
            <v-btn
              class="pkmn-button pkmn-button--has-border"
              color="primary"
              @click="submit"
              :disabled="!isFormValid"
            >
              {{ $t('settings_invite') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import auth from '@/auth'
import rules from '@/helpers/validation'

export default {
  name: 'FormAddUser',
  data() {
    return {
      alwaysOpen: true,
      auth: auth,
      rules: rules,
      formData: {
        first_name: '',
        last_name: '',
        role: 'merchant_user',
        email: '',
        login_url: this.loginURL
      },
      errorMessage: null
    }
  },
  computed: {
    loginURL() {
      if (process.env.VUE_APP_ENV === 'test') {
        return 'https://merchants.parkmansandbox.net/login'
      } else {
        return 'https://merchants.parkman.io/login'
      }
    },
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    roleForSuperAdminOptions() {
      return [
        {
          text: 'Admin',
          value: 'merchant_admin'
        },
        {
          text: 'Viewer',
          value: 'merchant_user'
        }
      ]
    },
    isFormValid() {
      return (
        this.formData.first_name.length !== 0 &&
        this.formData.last_name.length !== 0 &&
        this.formData.role.length !== 0 &&
        rules.isValidEmail(this.formData.email)
      )
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submit() {
      this.formSubmitted = true
      this.$emit('submit', this.formData)
    }
  },
  created() {
    this.formData.login_url = this.loginURL
  }
}
</script>
<style lang="scss">
@import '@/style/common';

.v-text-field__details {
  padding: 0px !important;
}

.col:has(.v-input--is-focused) {
  .text-input-label {
    color: $color-brand;
  }
}

.form-dialog--new-user .v-input--is-focused .v-input__slot {
  border: 1px solid $color-brand !important;
}

.form-dialog--new-user .v-input.error--text .v-input__slot {
  border: 1px solid $color-danger !important;
}
</style>
