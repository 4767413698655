<template>
  <v-dialog
    v-model="alwaysOpen"
    persistent
    content-class="set_report_settings"
    width="80%"
    max-width="512px"
    :fullscreen="isMobile"
    @click:outside="close"
  >
    <v-card class="form-dialog form-dialog--payout-id">
      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>
      <h1 class="form-dialog__title">{{ $t('set_report_settings') }}</h1>
      <div class="form-dialog__subtitle">
        <i18n path="set_report_settings_text" tag="span">
          <template v-slot:link>
            <a
              :href="templateLink"
              target="_blank"
              class="set_report_settings-link"
              >{{ $t('file') }}</a
            >
          </template>
        </i18n>
      </div>

      <v-form class="mt-8">
        <v-row dense>
          <v-row dense>
            <v-col cols="6">
              <div class="text-input-label">{{ $t('report_frequency') }}</div>
              <v-select
                v-model="formData.frequency_id"
                item-text="name"
                item-value="id"
                class="pkmn-input pkmn-input--inline text-capitalize"
                background-color="#F6F6F6"
                solo
                flat
                dense
                :disabled="!auth.isParkmanAdmin()"
                :items="frequencyTypes"
                :menu-props="{ contentClass: 'text-capitalize' }"
              >
                <template slot="selection">
                  {{ getFrequencyName(formData.frequency_id) }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="6">
              <div class="text-input-label">{{ $t('report_date') }}</div>
              <v-text-field
                disabled
                solo
                flat
                dense
                background-color="#F6F6F6"
                v-model="dateValue"
                class="pkmn-input"
              />
            </v-col>
          </v-row>

          <v-col cols="12">
            <div class="text-input-label">
              {{ $t('choose_report_template') }}
            </div>
            <v-select
              v-model="formData.template_id"
              item-text="name"
              item-value="id"
              class="pkmn-input text-capitalize"
              background-color="#F6F6F6"
              solo
              flat
              dense
              :disabled="!auth.isParkmanAdmin()"
              :menu-props="{ contentClass: 'text-capitalize' }"
              :items="reportTemplates"
            />
          </v-col>
          <!-- TODO uncomment when a preview of the template is added -->
          <!-- <v-col cols="12">
            <img
              class="set_report_settings-img"
              :src="getImgUrl"
              alt="report-preview"
            />
          </v-col> -->
        </v-row>
        <!-- TODO uncomment when a functionality for infoMessage is added -->
        <!-- <v-row>
          <v-col cols="12">
            <div class="set_report_settings-message pa-4 d-flex align-center">
              <div class="green-dot mr-4 ml-2 d-block"></div>
              <div>{{ infoMessage }}</div>
            </div>
          </v-col>
        </v-row> -->

        <v-row class="form-dialog__button-wrap mt-0">
          <v-col cols="12">
            <v-btn
              class="pkmn-button pkmn-button--has-border"
              color="primary"
              @click="submitForm"
            >
              {{ $t('form_save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <form-confirm-unsave
      v-if="confirmUnsaveReportDialogVisible"
      flow="Report Settings"
      @confirm="submitFormWithoutChanges"
      @close="confirmUnsaveReportDialogVisible = false"
    />
  </v-dialog>
</template>

<script>
import FormConfirmUnsave from '@/components/Merchant/Form/FormConfirmUnsave.vue'
import merchantHelpers from '@/helpers/merchant'
import { mapActions, mapGetters } from 'vuex'
import auth from '@/auth'

export default {
  name: 'SetReportPopup',
  components: {
    FormConfirmUnsave
  },
  props: {
    reportSettings: {
      type: Object,
      default: () => {}
    },
    merchant: {
      type: Object,
      default: () => {}
    },
    dialogShown: {
      type: Boolean,
      defaylt: false
    }
  },
  data() {
    return {
      auth,
      alwaysOpen: false,
      formData: {
        frequency_id: null,
        template_id: null
      },
      startedFormData: {},
      confirmUnsaveReportDialogVisible: false
    }
  },
  beforeDestroy() {
    this.clearData()
  },
  computed: {
    ...mapGetters(['reportTemplates', 'frequencyTypes']),
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    infoMessage() {
      return 'Current report frequency is Monthly (1st day). Starting from {dd.mm.yy} it will be Daily (00:00).'
    },
    dateValue() {
      const name = this.getFrequencyName(this.formData.frequency_id)
      return name === 'daily'
        ? '00:00'
        : name === 'monthly'
          ? '1st day'
          : name === 'weekly'
            ? 'Monday'
            : ''
    },
    templateLink() {
      const selectedTemplate = this.reportTemplates?.find(
        (template) => template.id === this.formData.template_id
      )
      return selectedTemplate ? selectedTemplate.template_file_path : ''
    }
  },
  watch: {
    reportTemplates: {
      immediate: true,
      async handler(list) {
        if (!list) {
          const templatesList = await merchantHelpers.fetchTemplatesList()
          this.setReportTemplates(templatesList || null)
        }
      }
    },
    frequencyTypes: {
      immediate: true,
      async handler(list) {
        if (!list) {
          const frequencyTypesList =
            await merchantHelpers.fetchFrequencyTypesList()
          this.setFrequencyTypesTemplates(frequencyTypesList || null)
        }
      }
    },
    reportSettings: {
      immediate: true,
      async handler(settings) {
        if (settings) {
          this.formData = { ...settings }
          this.setStartedFormData(settings)
        }
      }
    },
    dialogShown: {
      immediate: true,
      async handler(newVal) {
        if (newVal && !this.reportSettings?.id) {
          this.setDefaultValue()
        }
        this.alwaysOpen = newVal
      }
    }
  },
  methods: {
    ...mapActions(['setReportTemplates', 'setFrequencyTypesTemplates']),
    async close() {
      if (
        JSON.stringify(this.startedFormData) !== JSON.stringify(this.formData)
      ) {
        this.confirmUnsaveReportDialogVisible = true
      } else if (!this.startedFormData?.id) {
        await this.submitForm()
      } else {
        this.$emit('close', this.formData)
      }
    },
    async submitForm() {
      const report = await merchantHelpers.setReportSettings(
        this.merchant?.id,
        {
          frequency_id: this.formData.frequency_id,
          template_id: this.formData.template_id
        }
      )
      this.$emit('close', report)
    },
    submitFormWithoutChanges() {
      this.confirmUnsaveReportDialogVisible = false
      this.startedFormData?.id
        ? this.$emit('close', this.startedFormData)
        : this.submitForm()
    },
    getFrequencyName(id) {
      return this.frequencyTypes?.find((type) => type.id === id)?.name
    },
    setDefaultValue() {
      this.formData.frequency_id = this.frequencyTypes?.find(
        (type) => type.name === 'daily'
      )?.id
      this.formData.template_id = this.reportTemplates
        ? this.reportTemplates[0]?.id
        : 1
      this.setStartedFormData(this.formData)
    },
    setStartedFormData(data) {
      this.startedFormData = { ...data }
    },
    clearData() {
      this.formData = {
        frequency_id: null,
        template_id: null
      }
      this.startedFormData = {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.set_report_settings {
  &-link {
    font-weight: 700;
  }
  &-img {
    width: 100%;
  }
  &-message {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: $text-color-dark;
    border: 1px solid $color-brand;
    border-radius: 4px;
  }
  .green-dot {
    height: 8px;
    width: 8px;
    min-width: 8px;
    background-color: $color-brand;
    border-radius: 50%;
  }
}
</style>
