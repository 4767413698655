<template>
  <div ref="tableContainer" class="table-container">
    <v-data-table
      class="data__table data-table"
      ref="table"
      :headers="headers"
      :items="data"
      fixed-header
      hide-default-footer
      disable-pagination
      must-sort
      sort-by="email"
      :mobile-breakpoint="mobileBreakpoint"
    >
      <template v-slot:item.first_name="{ item }">
        <span>{{ `${item.first_name} ${item.last_name}` }}</span>
      </template>
      <template v-slot:item.role="{ item }">
        <span>{{ getDisplayRoleByName(item) }}</span>
      </template>
      <template v-slot:item.id="{ item }">
        <div class="mobile-row__action">
          <div v-if="auth.isParkmanAdmin() || auth.isMerchantAdmin()">
            <v-btn
              v-if="canDeleteUser(item.user_account_id)"
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="warning"
              @click="deleteUser(item)"
            >
              <img src="@/assets/button-delete.svg" />
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'DataTableUsers',
  props: ['data', 'showEdit'],
  data() {
    return {
      auth: auth,
      headers: [
        { text: 'Name', value: 'first_name' },
        { text: 'Email', value: 'email' },
        { text: 'Role', value: 'role' },
        {
          text: '',
          value: 'id',
          align: 'right',
          sortable: false,
          width: '178px'
        }
      ],
      mobileBreakpoint: 767 // screen width in pixel to switch to mobile view
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  mounted() {
    this.setTableContainerHeight() // Call the method when the component is mounted
    window.addEventListener('resize', this.setTableContainerHeight) // Update height on window resize
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setTableContainerHeight) // Remove the resize event listener when component is destroyed
  },
  methods: {
    setTableContainerHeight() {
      const windowHeight = window.innerHeight
      const containerTopOffset =
        this.$refs.tableContainer.getBoundingClientRect().top
      const containerMargin = 80 // Add any extra margin as needed

      const maxContainerHeight =
        windowHeight - containerTopOffset - containerMargin
      this.$refs.tableContainer.style.maxHeight = `${maxContainerHeight}px`
    },
    canDeleteUser(accountId) {
      return this.$store.getters.userData.account_id !== accountId
    },
    deleteUser(user) {
      this.$emit('delete', user)
    },
    getDisplayRoleByName(item) {
      if (item.role === 'merchant_user') {
        return 'Viewer'
      }

      if (item.role === 'merchant_admin') {
        return 'Admin'
      }

      if (item.role === 'parkman_admin') {
        return 'Admin'
      }

      return 'Unknown'
    }
  }
}
</script>

<style>
.table-container {
  overflow-y: auto; /* Add vertical scrollbar when content overflows */
  scrollbar-width: none !important;
}

.data-table {
  max-height: none !important; /* Reset the max-height set by v-data-table */
  scrollbar-width: none !important;
}
</style>
