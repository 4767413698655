<template>
  <v-dialog
    v-model="alwaysOpen"
    content-class="set_report_recipients"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="close"
  >
    <v-card class="form-dialog pb-0">
      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>

      <h1 class="form-dialog__title">{{ $t('set_report_recipients') }}</h1>

      <v-form class="mt-8">
        <v-row style="height: 72px">
          <v-col cols="12">
            <div class="d-flex">
              <div class="flex-grow-1 form-font mr-2">
                <div class="text-input-label">
                  {{ $t('recipient_lable') }}
                </div>
                <v-text-field
                  v-model="recipientsEmail"
                  ref="emailField"
                  class="pkmn-input"
                  background-color="#F6F6F6"
                  solo
                  flat
                  dense
                  validate-on-blur
                  :rules="[rules.email]"
                />
              </div>
              <v-btn
                class="pkmn-button pkmn-button--has-border set_report_recipients-btn mt-3"
                color="primary"
                @click="addRecevier"
              >
                <span class="text-capitalize">
                  {{ $t('button_add') }}
                </span>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="12">
            <v-list>
              <v-hover
                v-for="recipient in recipientsList"
                :key="recipient.id"
                v-slot:default="{ hover }"
              >
                <v-list-item
                  class="pl-0 pr-4 set_report_recipients-email"
                  :key="recipient.id"
                >
                  <v-list-item-title v-text="recipient.email" />
                  <v-list-item-action v-show="hover">
                    <img
                      src="@/assets/button-delete.svg"
                      @click="deleteRecipient(recipient)"
                    />
                  </v-list-item-action>
                </v-list-item>
              </v-hover>
            </v-list>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/helpers/validation'
import merchantHelpers from '@/helpers/merchant'

export default {
  name: 'SetReportRecipientsPopup',
  props: {
    recipientsList: {
      type: Array,
      default: () => []
    },
    reportSettings: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      alwaysOpen: true,
      recipientsEmail: '',
      shownAction: null,
      rules: { ...rules }
    }
  },
  mounted() {
    this.focusField()
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async addRecevier() {
      if (this.$refs.emailField.validate()) {
        const recevier = await merchantHelpers.addReceiver(
          this.reportSettings?.id,
          {
            email: this.recipientsEmail
          }
        )
        await this.$store.dispatch('setSystemMessage', {
          type: 'success',
          message: this.$t('added_confirm_message', { object: recevier.email })
        })
        this.$emit('refetchRecipients')
        this.recipientsEmail = ''
        this.focusField()
      }
    },
    async deleteRecipient(recipient) {
      await merchantHelpers.deleteReceiver(recipient)
      this.$emit('refetchRecipients')
    },
    focusField() {
      this.$refs.emailField.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
.set_report_recipients {
  &-btn {
    max-height: 38px;
  }
  &-email {
    &:hover {
      background-color: $color-brand-light;
    }
  }
}

::v-deep .col:has(.v-input--is-focused) {
  .text-input-label {
    color: $color-brand;
  }
}

::v-deep .set_report_recipients .v-input--is-focused .v-input__slot {
  border: 1px solid $color-brand !important;
}

::v-deep .set_report_recipients .v-input.error--text .v-input__slot {
  border: 1px solid $color-danger !important;
}
::v-deep .v-text-field--outlined.v-input--dense .v-label--active {
  transform: translateX(-10px) translateY(-28px) scale(0.75);
}
::v-deep .v-text-field--outlined fieldset legend {
  width: 0px !important;
}
::v-deep .v-list-item__action {
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
}
</style>
