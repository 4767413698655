export default [
  {
    text: 'Weekly',
    value: 'week'
  },
  {
    text: 'Monthly',
    value: 'month'
  },
  {
    text: 'Annually',
    value: 'year'
  }
]
