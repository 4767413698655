<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="close"
  >
    <v-card class="form-dialog form-dialog--payout-id">
      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>

      <h1 class="form-dialog__title">Add a payout ID</h1>
      <span class="form-dialog__subtitle">
        Decide how you want us to pay out money to your bank account, and create
        a payout ID. When you create new locations or products, payouts for each
        will be batched based on the payout ID
      </span>

      <v-form class="mt-8">
        <v-row dense>
          <v-col cols="12" style="height: 60px">
            <div class="text-input-label">Payout ID</div>
            <v-text-field
              solo
              flat
              dense
              background-color="#F6F6F6"
              v-model="formData.payout_id"
              class="pkmn-input"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <div class="text-input-label">Bank account number IBAN</div>
            <v-text-field
              solo
              flat
              dense
              background-color="#F6F6F6"
              v-model="formData.iban"
              class="pkmn-input"
              :hint="ibanNote"
            >
            </v-text-field>
          </v-col>

          <v-row dense>
            <v-col cols="6">
              <div class="text-input-label">Transfer frequency</div>
              <v-select
                solo
                flat
                dense
                background-color="#F6F6F6"
                v-model="formData.transfer_freq"
                class="pkmn-input pkmn-input--inline"
                :items="freqOptions"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <div class="text-input-label">Transfer date</div>
              <v-select
                solo
                flat
                dense
                background-color="#F6F6F6"
                v-model="formData.transfer_date"
                class="pkmn-input"
                :items="dateOptions"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6">
              <div class="text-input-label">Report frequency</div>
              <v-select
                solo
                flat
                dense
                background-color="#F6F6F6"
                v-model="formData.report_freq"
                class="pkmn-input pkmn-input--inline"
                :items="freqOptions"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <div class="text-input-label">Report date</div>
              <v-select
                solo
                flat
                dense
                background-color="#F6F6F6"
                v-model="formData.report_freq"
                class="pkmn-input"
                :items="dateOptions"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-col cols="12">
            <div class="text-input-label">Message of reference</div>
            <v-text-field
              solo
              flat
              dense
              background-color="#F6F6F6"
              v-model="formData.message_ref"
              class="pkmn-input"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <div class="text-input-label">Report receiver email</div>
            <v-text-field
              solo
              flat
              dense
              background-color="#F6F6F6"
              v-model="formData.receiver_email"
              class="pkmn-input"
              :rules="[emailRules]"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense v-if="errorMessage">
          <v-col cols="12" class="text-right">
            <p class="pkmn-form__error" v-html="errorMessage"></p>
          </v-col>
        </v-row>

        <v-row class="form-dialog__button-wrap">
          <v-col cols="12">
            <v-btn
              class="pkmn-button pkmn-button--has-border"
              color="primary"
              @click="submit"
            >
              Add
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import frequency from '../transfer_freq'
import rules from '@/helpers/validation'

export default {
  name: 'FormAddPayoutId',
  data() {
    return {
      alwaysOpen: true,
      formData: {
        payout_id: '',
        iban: '',
        transfer_freq: '',
        transfer_date: '',
        report_freq: '',
        report_date: '',
        message_ref: '',
        receiver_email: ''
      },
      ibanNote: 'FI00 0000 0000 0000',
      freqOptions: frequency,
      dateOptions: [
        {
          text: 1,
          value: 1
        },
        {
          text: 2,
          value: 2
        }
      ],
      rules: rules,
      errorMessage: null
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    emailRules() {
      return [(v) => !v || rules.email || 'Email must be valid']
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submit() {
      this.formSubmitted = true
      this.submitForm()
    },
    async submitForm() {
      this.errorMessage = null
      this.$emit('submit', this.formData)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
</style>
